/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });
import Cookies from "js-cookie";

Vue.use(Vuex);

import moduleELearning from "./elearning";
import moduleAuth from "./auth";
import cms from "./cms";
import job from "./job";
import author from "./author";
import topic from "./topic";
import cart from "./cart";
import wishlist from "./wishlist";
import placeOrder from "./placeOrder";
import learningTracker from "./learningTracker";
import student from "./student";
import google from "./google";
import moduleChannel from "./channel";
import modulePosts from "./posts";
import UI from "./UI";
import admin from "./admin";
import membershipPlan from "./membershipPlan";
import membership from "./membership";
import payment from "./payment";
import members from "./members";
import user from "./user";
import ecommerce_1 from "./ecommerce-1";
import network from "./network";
import organisation from "./organisation";
import master from "./master";
import registration from "./registration";
import awards from "./awards";
import coupon from "./coupon";
import contactUs from "./contactUs";
import benefits from "./benefits";
import conference from "./conference";
import publication from "./publication";
let initialStateModules = {
  ...ecommerce_1,
  auth: moduleAuth,
  eLearning: moduleELearning,
  channel: moduleChannel,
  posts: modulePosts,
  UI,
  cms,
  job,
  author,
  topic,
  cart,
  wishlist,
  placeOrder,
  learningTracker,
  student,
  google,
  admin,
  membershipPlan,
  membership,
  payment,
  members,
  user,
  network,
  organisation,
  master,
  contactUs,
  registration,
  awards,
  coupon,
  benefits,
  conference,
  publication
};
export default new Vuex.Store({
  modules: initialStateModules,
  strict: process.env.NODE_ENV !== "production",
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  // plugins: [
  //   createPersistedState({
  //     // storage: window.sessionStorage,
  //     storage: {
  //       getItem: (key) => Cookies.get(key),
  //       setItem: (key, value) =>
  //         Cookies.set(key, value, { expires: 3, secure: true }),
  //       removeItem: (key) => Cookies.remove(key),
  //     },
  //   }),
  // ],
});

// window.ls = ls;

// export { ls };
