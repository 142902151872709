import RepositoryFactory from "@/apis";
const publication = RepositoryFactory.publication;
const user = RepositoryFactory.user;

export default {

  get({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await publication.get(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  add({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await publication.Add(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  update({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await publication.update(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  delete({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await publication.delete(payload);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

};
