import Repository from "./Repository";

const formDataConfig = {
  headers: { "Content-Type": "multipart/form-data" },
};
const ADD = "add";
const update = "update";
const get = "get";
const delet = "delete";

export default {
  Add(payload) {
    return Repository.post(transformRoute(ADD), payload, formDataConfig);
  },
  update(payload) {
    return Repository.post(transformRoute(update), payload, formDataConfig);
  },
  get(payload) {
    return Repository.post(transformRoute(get), payload);
  },
  delete(payload) {
    return Repository.post(transformRoute(delet), payload);
  },
};

const transformRoute = (route) => {
  return `/publication/${route}`;
};
